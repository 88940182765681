import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('h1',[_vm._v("Load ART")]),_c(VBtn,{on:{"click":function($event){return _vm.load_last_3()}}},[_vm._v("Last 3")]),_c(VBtn,{on:{"click":function($event){return _vm.add()}}},[_vm._v("Add Art")])],1),_c('div',[_c(VRow,_vm._l((_vm.articoli),function(articolo){return _c(VCol,{key:articolo.id,attrs:{"cols":"12","sm":"3","md":"2"}},[_c(VCard,{attrs:{"color":"primary"}},[_c(VCardText,[_c('h3',[_vm._v(" "+_vm._s(articolo.title)+" ")])])],1)],1)}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }