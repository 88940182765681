<template>
    <div>
        <div>
            <h1>Load ART</h1>
            <v-btn @click="load_last_3()">Last 3</v-btn>
            <v-btn @click="add()">Add Art</v-btn>
        </div>

        <div>
            <v-row>
                <v-col cols="12" sm="3" md="2" v-for="articolo in articoli" :key="articolo.id">
                    <v-card color="primary">
                        <v-card-text>
                            <h3>
                                {{ articolo.title }}
                            </h3>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>

    </div>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => ({

    }),
    methods: {
        add() {
            const payload = {
                title: {
                    it: "Titolo",
                    en: "Title",
                },
                stato: 0,
                creation: new Date()
            }
            this.$store.dispatch("articoli/act_test_write_articolo", payload);
            this.$act_test_write_articolo
        },
        load_last_3() {
            this.$store.dispatch("articoli/act_get_article_batch_from_latest")
        }
    },
    mounted() {
    },
    created() { },
    computed: {
        articoli() {
            return this.$store.getters["articoli/get_articoli"]
        },

    },
    watch: {}
}
</script>

<style>

</style>